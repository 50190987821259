var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"support -s-m-120",attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"support__wr"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"support__text"},[(_vm.blok.title)?_c(_vm.heading,{tag:"component",staticClass:"support__text-title -h1 -ws-pl",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"},domProps:{"innerHTML":_vm._s(_vm.blok.title)}}):_vm._e(),_vm._v(" "),(_vm.blok.description)?_c('p',{staticClass:"support__text-descr -t3 -ws-pl",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up","data-aos-delay":"150"},domProps:{"innerHTML":_vm._s(_vm.blok.description)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"support__list"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"support__list-line hide-mob",class:[_vm.aosAnimate],attrs:{"data-aos":"fade","data-aos-delay":"200","data-src":require("assets/img/icons/support-line.svg"),"alt":"Line","width":"1360","height":"160"}}),_vm._v(" "),_c('div',{staticClass:"support__list-wr -g-d-f -g-jc-c -g-fw-w"},_vm._l((_vm.blok.list),function(item,idx){return _c('UiLinkSupport',{key:item._uid,staticClass:"support__list-item",class:[_vm.aosAnimate],attrs:{"data-aos":"fade","data-aos-delay":(150 * idx) + 300,"blok":item}})}),1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }